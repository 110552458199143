export function delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export function formatToDateTime(date: string) {
  return new Intl.DateTimeFormat('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  })
    .format(new Date(date))
    .replace(',', '')
}
