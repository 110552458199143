import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  padding: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`
export const Description = styled.h5`
  font-size: 0.8rem;
  font-weight: 500;
  color: #707070;
`
export const Info = styled.h6`
  font-size: 1rem;
  margin-left: 5px;
  color: #333;
`
