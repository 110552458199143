import React, {
  useState,
  createContext,
  SetStateAction,
  Dispatch,
  ReactNode
} from 'react'
import { TCashbacks } from '../types/TCashbacks'
import { TCashbackStatus } from '../types/TCashbackStatus'

interface IData {
  cashbacks: TCashbacks
  setCashbacks: Dispatch<SetStateAction<TCashbacks>>

  cashbackStatus: TCashbackStatus[]
  setCashbackStatus: Dispatch<SetStateAction<TCashbackStatus[]>>
}

export const CCashbacks = createContext<IData>({} as IData)

interface CashbacksProviderProps {
  children: ReactNode
}

export default function CashbacksProvider({
  children
}: CashbacksProviderProps) {
  const [cashbacks, setCashbacks] = useState({} as TCashbacks)
  const [cashbackStatus, setCashbackStatus] = useState(
    [] as Array<TCashbackStatus>
  )

  return (
    <CCashbacks.Provider
      value={{
        cashbacks,
        setCashbacks,
        cashbackStatus,
        setCashbackStatus
      }}
    >
      {children}
    </CCashbacks.Provider>
  )
}
