import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #eff3f5;
`
export const Label = styled.h4`
  color: #bababa;
  font-size: 1rem;
  font-weight: 400;
  margin-top: 20px;
`
