export default {
  title: 'light',

  colors: {
    'white-100': '#FFFFFF',
    'white-300': '#EFF3F5',
    'blue-500': '#43A0E7',
    'blue-600': '#1E9FD3',
    'blue-700': '#0984E3',
    'slate-300': '#768693',
    'slate-600': '#3A4D5C',
    'gray-300': '#B2B2B2',
    'gray-600': '#707070',
    'gray-700': '#645D5D',
    'gray-900': '#333333',
    'red-400': '#ff6666',
    'red-500': '#ef4444',
    'green-500': '#00BF78',
    'green-600': '#009933',
    'yellow-400': '#F7CB19'
  },

  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200
  },

  shadows: {
    sm: `
      -webkit-box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.02);
      -moz-box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.02);
      box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.02);
    `,
    md: `
      -webkit-box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.02);
      -moz-box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.02);
      box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.02);
    `,
    lg: `
      -webkit-box-shadow: 3px 3px 15px -10px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 3px 3px 15px -10px rgba(0, 0, 0, 0.75);
      box-shadow: 3px 3px 15px -10px rgba(0, 0, 0, 0.75);
    `
  }
}
