import React from 'react'
import * as S from './style'

export const PrivacyPolicy: React.FC = () => {
  return (
    <S.Container>
      <S.Content>
        <S.Title>Política de Privacidade</S.Title>
        <S.Paragraph>
          A TakeBack tem o compromisso com a transparência, a privacidade e a
          segurança dos dados de seus usuários durante todo o processo de
          interação com nosso site, aplicativo e da plataforma. Para que
          entendam melhor quais informações coletamos e como as utilizamos,
          armazenamos ou excluímos, detalhamos a seguir nossa Política de
          Privacidade. Os dados cadastrais dos clientes não são divulgados para
          terceiros, exceto quando necessários para o processo de cobrança,
          atendimento ao consumidor, disponibilização de benefícios, promoções e
          pesquisas de satisfação. Seus dados pessoais são peça fundamental para
          que o pedido chegue em segurança na sua casa, de acordo com o prazo de
          entrega estipulado. Para que a Política de Privacidade seja bem
          compreendida, é fundamental esclarecer alguns conceitos importantes:
          <br></br>
          <br></br>
          <ul>
            <li>
              Usuário – toda pessoa física que utilizará a plataforma e o
              aplicativo.
            </li>
            <li>
              Dados pessoais – qualquer informação relacionada a uma pessoa que
              a identifique ou que, usada em combinação com outras informações
              tratadas, identifique um indivíduo. Ainda, qualquer informação por
              meio da qual seja possível identificar uma pessoa ou entrar em
              contato com ela.
            </li>
            <li>
              Tratamento de dados pessoais – considera-se tratamento de dado
              pessoal a coleta, produção, recepção, classificação, utilização,
              acesso, reprodução, transmissão, distribuição, processamento,
              arquivamento, armazenamento, eliminação, avaliação ou controle da
              informação, comunicação, transferência, difusão ou extração de
              dados de pessoas físicas.
            </li>
            <li>
              Titular de dados – qualquer pessoa física que tenha seus dados
              pessoais tratados pela TakeBack.
            </li>
            <li>
              Finalidade – o que queremos alcançar com o tratamento de dados
              pessoais.
            </li>
            <li>
              Necessidade – o tratamento de dados pessoais deve se limitar ao
              mínimo necessário para o propósito almejado. Ou seja, deve ser
              pertinente, proporcional e não excessivo.
            </li>
            <li>
              Consentimento – autorização clara e objetiva que o titular dá para
              tratamento de seus dados pessoais com finalidade previamente
              estipulada. Após dar o consentimento, você pode revogá-lo a
              qualquer momento. A revogação não cancela os processamentos
              realizados previamente.
            </li>
          </ul>
        </S.Paragraph>
        <h4>1. A quem essa política de privacidade se aplica?</h4>
        <S.Paragraph>
          Aplica-se a todos os usuários da plataforma e do aplicativo TakeBack,
          que de alguma forma tenham os dados pessoais tratados por nós.
        </S.Paragraph>
        <h4>2. Que tipo de informações pessoais coletamos e utilizamos?</h4>
        <S.Paragraph>
          A TakeBack coleta e armazena os seguintes tipos de informações:
          <br></br>
          <ul>
            <li>
              Informações que você nos fornece: a TakeBack coleta as informações
              que você fornece quando utiliza nossa plataforma, inclusive quando
              cria uma conta no nosso aplicativo. Isso pode incluir seu nome
              completo, email, CPF, endereço, números de telefone e data de
              nascimento. A TakeBack poderá pedir que você forneça também dados
              sensíveis, como biometria, fotos para fins de prevenção à fraude e
              garantia de segurança aos serviços contratados. Podemos coletar
              informações adicionais de e sobre você quando você se comunica
              conosco, através do nosso Serviço de Atendimento ao Cliente (SAC),
              ou quando responde a uma pesquisa.
            </li>
            <li>
              Informações sobre transações realizadas em nossa Plataforma:
              quando você utiliza a plataforma para compras ou outras transações
              financeiras, nós coletamos informações sobre a compra ou
              transação. Isso inclui informações de pagamento, como o valor,
              informações de conta e autenticação, bem como detalhes de
              cobrança, entrega e contato.
            </li>
            <li>
              Informações coletadas pelo dispositivo móvel: Quando você baixa ou
              utiliza o aplicativo da TakeBack, podemos coletar dados sobre a
              localização do seu dispositivo móvel. Podemos utilizar essas
              informações para fornecer serviços de localização, como, por
              exemplo, anúncios, resultados de buscas e outros conteúdos
              personalizados. A maioria dos dispositivos móveis permite que você
              desligue os serviços de localização, então caso não queira
              disponibilizar a localização, acesse o dispositivo e desative a
              permissão de uso.
            </li>
            <li>
              Informações sobre você de outras fontes: Podemos obter informações
              de terceiros, como outros usuários da TakeBack (quando, por
              exemplo, você recebe pagamentos ou transferências pela
              plataforma), de fontes públicas (por exemplo, dados demográficos),
              agências ou órgãos de crédito (por exemplo, relatórios de
              crédito/cheques), provedores de dados (por exemplo, dados
              demográficos relacionados a anúncios publicitários on-line e com
              base nos interesses do Cliente) e empresas que vendem serviços com
              o meio de pagamento TakeBack. Nós também podemos obter informações
              sobre você com empresas parceiras para, dentre outras finalidades
              indicadas nesta política, confirmar seus dados e informações
              fornecidas.
            </li>
          </ul>
        </S.Paragraph>
        <h4>3. por que solicitamos seus dados pessoais?</h4>
        <S.Paragraph>
          Os dados são necessários para:
          <ul>
            <li>entregar o serviço contratado;</li>
            <li>otimizar sua interação conosco;</li>
            <li>
              garantir a segurança do site, seu aplicativo, plataforma e dos
              dados que processa;
            </li>
            <li>
              informar o usuário sobre as ofertas e divulgar os nossos serviços;
            </li>
            <li>cumprir obrigações legais;</li>
            <li>fins administrativos e de gestão.</li>
          </ul>
          <br></br>O fornecimento dos dados permite:
          <ul>
            <li>entregar os serviços adquiridos;</li>
            <li>
              coordenar com parceiros o gerenciamento de pagamento de serviços
              ou produtos adquiridos através do TakeBack;
            </li>
            <li>prestar serviços adequados às necessidades do usuário;</li>
            <li>melhorar a experiência do usuário na TakeBack;</li>
            <li>fazer análises e pesquisas de mercado;</li>
            <li>
              manter o usuário informado sobre os produtos e serviços que
              oferecemos;
            </li>
            <li>executar publicidade online direcionada;</li>
            <li>
              prevenir, detectar e investigar atividades que estejam em
              desacordo com o nosso Código de Conduta ou que sejam proibidas ou
              ilegais;{' '}
            </li>
            <li>melhorar nosso site, aplicativo e plataforma.</li>
          </ul>
          <br></br> A TakeBack utiliza bases legais, que podem variar de acordo
          com a finalidade da coleta, para tratar os dados pessoais dos
          usuários. O prazo de armazenamento pode mudar conforme a base legal
          aplicável a cada situação e a finalidade.
        </S.Paragraph>

        <h5>3.1 Serviços de marketing</h5>
        <S.Paragraph>
          Os serviços de marketing são oferecidos por meio de comunicações
          gratuitas sobre ofertas dirigidas ao usuário, relacionadas ao seu
          perfil no site e a compras que fez. Esse serviço abrange e-mails,
          notificações e SMS. Vale ressaltar que o usuário pode cancelar o
          serviço, a qualquer momento, solicitando o mesmo por meio dos nossos
          canais de atendimento.
        </S.Paragraph>

        <h4>4. Com quem compartilhamos seus dados?</h4>
        <S.Paragraph>
          <ul>
            <li>
              I) As informações coletadas são tratadas dentro da TakeBack LTDA e
              somente serão compartilhadas quando forem necessárias:{' '}
            </li>
            <li>
              II) para prestação adequada dos serviços objeto de suas atividades
              com empresas parceiras;{' '}
            </li>
            <li>III) para proteção em caso de conflito; </li>
            <li>
              IV) mediante decisão judicial ou requisição de autoridade
              competente;{' '}
            </li>
            <li>
              V) com empresas provedoras de infraestrutura tecnológica e
              operacional, como empresas intermediadoras de pagamento e
              provedoras de serviço de armazenamento de informações.
            </li>
          </ul>
        </S.Paragraph>
        <h4>5. transferência internacional de dados</h4>
        <S.Paragraph>
          Como a internet é um ambiente global, determinados serviços oferecidos
          pela TakeBack podem demandar a transferência dos seus dados para
          outros países. Nesses casos, os dados são tratados de acordo com a
          LGPD (Lei Geral de Proteção de Dados) e demais legislações de
          proteção. Tomamos medidas de segurança de acordo com nossas políticas
          e adotamos cláusulas padrão nos contratos com fornecedores e
          prestadores de serviço. Ao utilizar a plataforma, aplicativo e/ou se
          comunicar conosco, você concorda com o tratamento de suas informações,
          inclusive a transferência internacional de dados, quando necessário.
          Adotamos medidas para garantir que quaisquer informações coletadas
          sejam tratadas com segurança, conforme os padrões de proteção de dados
          e de acordo com esta Política de Privacidade.
        </S.Paragraph>

        <h4>Por quanto tempo armazenamos informações pessoais?</h4>
        <S.Paragraph>
          Armazenamos as informações dos Clientes de acordo com as normas de
          prescrição do Direito brasileiro.
        </S.Paragraph>

        <h4>7. Quais são os direitos do titular de dados?</h4>
        <S.Paragraph>
          O titular dos dados pessoais possui o direito de obter da TakeBack, a
          qualquer momento, mediante requisição formal, informações referentes
          aos seus dados. A TakeBack terá 15 dias úteis para responder às
          solicitações dos titulares. Os pedidos serão analisados conforme
          previsto em legislação vigente e, por questões legais, algumas
          solicitações podem não ser atendidas. Os titulares dos dados, segundo
          o texto da LGPD, podem exercer os seus direitos por meio de:
          <ul>
            <li>I) confirmação da existência de tratamento;</li>
            <li>I) confirmação da existência de tratamento;</li>
            <li>
              III) correção de dados incompletos, inexatos ou desatualizados;
            </li>
            <li>
              IV) anonimização, bloqueio ou eliminação de dados desnecessários,
              excessivos ou tratados em desconformidade com o disposto nesta
              Lei;
            </li>
            <li>
              V) portabilidade dos dados a outro fornecedor de serviço ou
              produto, mediante requisição expressa, de acordo com a
              regulamentação da autoridade nacional, observados os segredos
              comercial e industrial;
            </li>
            <li>
              VI) eliminação dos dados pessoais tratados com o consentimento do
              titular;
            </li>
            <li>
              VII) informação das entidades públicas e privadas com as quais o
              controlador realizou uso compartilhado de dados;
            </li>
            <li>
              VIII) informação sobre a possibilidade de não fornecer
              consentimento e sobre as consequências da negativa;
            </li>
            <li>IX) revogação do consentimento.</li>
          </ul>
        </S.Paragraph>

        <h4>8. Cookies</h4>
        <S.Paragraph>
          Cookie é um identificador (em formato de arquivo de texto) que pode
          ser gerado, ou coletado, do seu celular ou navegador para:
          <ul>
            <li>A. Disponibilizar uma página para acesso; ou</li>
            <li>B. Identificar seu perfil de navegação.</li>
          </ul>
          Eles nos permitem oferecer uma experiência personalizada de acesso
          para você, nos ajudando a analisar por onde você navega na internet e,
          assim, conseguimos te oferecer produtos que tenham compatibilidade com
          suas buscas. Os cookies não dão acesso ao seu computador ou ao seu
          celular e eles não revelam informações além daquelas que você escolheu
          compartilhar com a gente. Como trabalhamos com parceiros de negócios e
          prestadores de serviços, é possível que eles também utilizem essas
          informações coletadas através dos cookies, oferecendo produtos e
          serviços cada vez mais personalizados. É sempre bom lembrar que você
          pode gerenciar ou desabilitar os cookies nas configurações do seu
          navegador. No entanto, lembramos que, como alguns deles são
          necessários para possibilitar a utilização do nosso site, ao
          desabilitar todos os cookies pode ser que não consiga acessar
          integralmente nosso site.
        </S.Paragraph>

        <h4>9. Como exercer os seus direitos?</h4>
        <S.Paragraph>
          Você pode exercer seus direitos acessando a seção “Painel de
          Privacidade”, no site da TakeBack. Além disso, pode entrar em contato
          pelo e-mail privacidade@takeback.app.br. Se não quiser receber nossas
          comunicações de marketing, também pode cancelar a assinatura clicando
          no link enviado junto com o e-mail do marketing.
        </S.Paragraph>

        <h4>10. Como você pode manter suas informações pessoais seguras?</h4>
        <S.Paragraph>
          A TakeBack utiliza os melhores protocolos de segurança para preservar
          a privacidade dos dados dos usuários, mas também recomenda medidas de
          proteção individual. Para manter a segurança e proteção dos seus dados
          pessoais fornecidos no cadastro, em nenhuma hipótese o seu login e
          senha devem ser compartilhados com terceiros. Além disso, ao acessar o
          seu cadastro pelo “Perfil”, principalmente em computadores públicos,
          certifique-se de que você realizou o logout da sua conta para evitar
          que pessoas não autorizadas acessem e utilizem as informações sem o
          seu conhecimento. Não entramos em contato por telefone, WhatsApp, SMS
          ou e-mail solicitando dados pessoais. Em nenhuma hipótese eles devem
          ser fornecidos, pois pode ser uma tentativa de uso indevido.
        </S.Paragraph>

        <h4>11. Outras informações importantes sobre proteção de dados</h4>
        <S.Paragraph>
          Para garantir que as regras estão claras e precisas, podemos alterar
          essa política a qualquer momento, publicando a Política de Privacidade
          revisada neste site e indicando a data de efetivação do documento. Se
          alguma mudança substancial for feita, você receberá uma notificação
          por e-mail.
        </S.Paragraph>

        <h4>
          12. Como entrar em contato com o encarregado da proteção de dados da
          TakeBack?
        </h4>
        <S.Paragraph>
          O encarregado da proteção de dados é o responsável escolhido pela
          TakeBack para atuar como canal de comunicação entre o controlador, os
          titulares dos dados e a Autoridade Nacional de Proteção de Dados
          (ANPD). Qualquer dúvida sobre tratamento de dados pessoais poderá ser
          encaminhada para o e-mail privacidade@takeback.app.br.
        </S.Paragraph>

        <h4>13. Legislação e foro</h4>
        <S.Paragraph>
          Esta política será regida, interpretada e executada de acordo com as
          Leis da República Federativa do Brasil, especialmente a Lei nº
          13.709/2018, independentemente das Leis de outros estados ou países,
          sendo competente o foro de domicílio do Usuário para dirimir qualquer
          dúvida decorrente deste documento.
        </S.Paragraph>
        <br></br>
        <h6>Última atualização: 22 de Março de 2022.</h6>
        <S.ForgotPass to="/">Voltar</S.ForgotPass>
      </S.Content>
    </S.Container>
  )
}
